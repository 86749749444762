import { Button, Card, Input, Radio, RadioGroup } from '@nextui-org/react';
import { PayPalButtons, PayPalMarks, PayPalScriptProvider } from '@paypal/react-paypal-js';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { httpClient } from '../axios';
import CustomRadio from './CustomRadio';
import { v4 as uuid } from "uuid";
import { useNavigate, useParams, useLocation } from "react-router";
import back from "../assets/images/back1.jpg";

const plansList = [
    {
      id: 1,
      name: "Annual",
      value: 250,
    },
    {
      id: 2,
      name: "Lifetime",
      value: 2500,
    },
  ];
  
const Checkout = () => {
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [planSelect, setPlanSelect] = useState(plansList[0]);
    const [paymentMethod, setPaymentMethod] = useState("full");
    const [partailPayment, setPartialPayment] = useState(0);
    const [selectedFundingSource, setSelectedFundingSource] = useState( );
    useEffect(() => {
        console.log(location);
      setPaymentMethod("full")
      localStorage.setItem("pay", "full");
      localStorage.setItem("plan", JSON.stringify(plansList[0]));
    }, []);
    const initialOptions = {
        "client-id":
          "AU9qMUHJnX0GbemLBe1DOJJCln7DF9e1Toinj1vK1TeSk2gn2xdOvhFALMrgoTvzkaaYeA8NAY8buwsD",
        currency: "USD",
        intent: "capture",
        components: "marks,buttons",
      };
    
      const fundingSources = ["paypal", "card"];
    
      const [isCheck, setIsCheck] = useState(false);
      const [isChequeNo, setIsChequeNo] = useState(null);
      const [isChequeValid, setIsChequeValid] = useState(false);
      // Remember the amount props is received from the control panel
     
    
      function onChange(event, type = "paypal") {
        // console.log(event.target.value);
        if (type == "paypal") {
          setSelectedFundingSource(event.target.value);
          setIsCheck(false);
        } else {
          setSelectedFundingSource(null)
          setIsCheck(true);
        }
      }
    
      async function createOrder() {
        try {
          const response = await fetch(
            "https://determined-boa-pea-coat.cyclic.app/api/orders",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              // use the "body" param to optionally pass additional order information
              // like product ids and quantities
              body: JSON.stringify({
                cart: [
                  {
                    name: JSON.parse(localStorage.getItem("plan")).name,
                    value:
                      localStorage.getItem("pay") == "partial"
                        ? JSON.parse(localStorage.getItem("plan")).value / 2
                        : JSON.parse(localStorage.getItem("plan")).value,
                  },
                ],
              }),
            }
          );
    
          const orderData = await response.json();
    
          console.log(orderData);
    
          if (orderData.id) {
            return orderData.id;
          } else {
            const errorDetail = orderData?.details?.[0];
            const errorMessage = errorDetail
              ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
              : JSON.stringify(orderData);
    
            throw new Error(errorMessage);
          }
        } catch (error) {
          console.error(error);
          console.log(`Could not initiate PayPal Checkout...<br><br>${error}`);
        }
      }
    
      async function onApproveFunc(data, actions) {
        console.log(data);
        try {
          const response = await fetch(
            `https://determined-boa-pea-coat.cyclic.app/api/orders/${data.orderID}/capture`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
    
          const orderData = await response.json();
          // Three cases to handle:
          //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
          //   (2) Other non-recoverable errors -> Show a failure message
          //   (3) Successful transaction -> Show confirmation or thank you message
    
          const errorDetail = orderData?.details?.[0];
    
          if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
            return actions.restart();
          } else {
            const transaction =
              orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
              orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
    
            console.log(transaction);
            savePayment(transaction, orderData?.payer);
    
            // transaction.status === "COMPLETED"
            //   ? toast.success("Transaction Completed", transaction.status)
            //   : toast.success("Transaction Failed", transaction.status);
          }
        } catch (error) {
          console.error(error);
          console.log(
            `Sorry, your transaction could not be processed...<br><br>${error}`
          );
        }
      }
    
      const changePaymentMethod = (e) => {
        console.log(e);
        localStorage.setItem("pay", e);
        setPaymentMethod(e);
        if (e === "partial") {
          setPartialPayment(planSelect.value / 2);
        } else {
          setPartialPayment(0);
        }
      };
    
      const onPlanChange = (e) => {
        localStorage.setItem("plan", JSON.stringify(e));
        setPlanSelect(e);
        setPartialPayment(0);
        setPaymentMethod("full");
      };
    const savePayment = async (pay, user) => {
        console.log(isCheck);
        if (isCheck) {
          if (isChequeNo?.length > 0 || isChequeNo !== null) {
            const payment = {
              emailid: location.state?.email,
              trandate: pay?.create_time,
              transactionid: isCheck ? uuid() : pay?.id,
              planname: planSelect.name,
              planvalue: isCheck
                ? localStorage.getItem("pay") == "partial"
                  ? planSelect.value / 2
                  : planSelect.value
                : pay?.amount?.value,
              status: isCheck ? "COMPLETED" : pay?.status,
              paymenttype: paymentMethod,
              mode: isCheck ? "cheque" : "online",
              chequeno: isChequeNo,
            };
    
            // console.log(payment);
    
            httpClient.post("/transaction", payment).then((res) => {
              try {
                // console.log(res);
                navigate("/success");
                localStorage.setItem(
                  "transaction",
                  JSON.stringify(res.data.result)
                );
                toast.success(res.data.message);
              } catch (err) {
                toast.error("Something went wrong");
              }
            });
          } else {
            setIsChequeValid(true);
          }
        } else {
          const payment = {
            emailid: location.state?.email,
            trandate: pay?.create_time,
            transactionid: isCheck ? uuid() : pay?.id,
            planname: planSelect.name,
            planvalue: isCheck
              ? localStorage.getItem("pay") == "partial"
                ? planSelect.value / 2
                : planSelect.value
              : pay?.amount?.value,
            status: isCheck ? "COMPLETED" : pay?.status,
            paymenttype: paymentMethod,
            mode: isCheck ? "cheque" : "online",
            chequeno: isChequeNo,
          };
    
          // console.log(payment);
    
          httpClient.post("/transaction", payment).then((res) => {
            try {
              // console.log(res);
              navigate("/success");
              localStorage.setItem("transaction", JSON.stringify(res.data.result));
              toast.success(res.data.message);
            } catch (err) {
              toast.error("Something went wrong");
            }
          });
        }
      };
    
  return (
    <div className='h-svh flex justify-center items-center' style={{ backgroundImage: `url(${back})`, backgroundSize: "cover" }}>
         <Card className="p-8 w-full md:w-[55vw] overflow-y-scroll h-[90vh] md:h-auto md:overflow-auto" >
              <PayPalScriptProvider options={initialOptions}>
                <RadioGroup
                  value={planSelect}
                  onValueChange={onPlanChange}
                  className="w-full "
                  label="Plans"
                >
                  {plansList?.map((item) => {
                    return (
                      <CustomRadio
                        key={item.id}
                        description={`$${item.value}`}
                        value={item}
                      >
                        {item.name}
                      </CustomRadio>
                    );
                  })}
                </RadioGroup>
                <RadioGroup
                  value={paymentMethod}
                  onValueChange={changePaymentMethod}
                  label="Select Payment Method"
                  color="primary"
                  className="my-4"
                >
                  <Radio value="full">Full Payment</Radio>
                  <Radio
                    value="partial"
                    description="Rest Amount will be paid after six months"
                  >
                    <div className="relative">
                      <span>Partial Payment </span>
                      {partailPayment != 0 && (
                        <span className="absolute -right-40 font-medium">
                          ${partailPayment} to be paid after 6 months
                        </span>
                      )}
                    </div>
                  </Radio>
                </RadioGroup>
                <div className="flex gap-8 items-center my-4">
                  {fundingSources.map((fundingSource) => (
                    <label
                      className="mark flex  items-center  gap-2"
                      key={fundingSource}
                    >
                      <input
                        onChange={(e) => onChange(e)}
                        type="radio"
                        name="fundingSource"
                        checked={selectedFundingSource === fundingSource}
                        value={fundingSource}
                      />
                      <PayPalMarks fundingSource={fundingSource} />
                    </label>
                  ))}
                  <div className="flex items-center gap-2">
                    <input
                      value={isCheck}
                      checked={isCheck}
                      onChange={(e) => onChange(e, "check")}
                      type="radio"
                    />
                    <span className="border p-1 cursor-default">Cheque</span>
                  </div>
                </div>
                <PayPalButtons
                  className={`${isCheck ? "hidden" : "flex"}`}
                  fundingSource={selectedFundingSource}
                  createOrder={createOrder}
                  onApprove={onApproveFunc}
                  style={{ layout: "vertical", tagline: false }}
                />
              </PayPalScriptProvider>
              <div
                className={`${!isCheck ? "hidden" : "flex"} items-center gap-4`}
              >
                <Input
                  value={isChequeNo}
                  onChange={(e) => setIsChequeNo(e.target.value)}
                  type="text"
                  isRequired
                  isInvalid={Boolean(isChequeValid && isChequeNo == null)}
                  label="Cheque Number"
                />
                <Button onClick={savePayment} color="primary">
                  Submit
                </Button>
              </div>
            </Card>
    </div>
  )
}

export default Checkout