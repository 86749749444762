import React from "react";
import MainNavbar from "./MainNavbar";
import bgBlue from "../assets/images/bgGrey.jpg";
import { toast } from "react-toastify";
import { httpClient } from "../axios";
import { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "@nextui-org/react";
import { useState } from "react";
import { useNavigate } from "react-router";

const Home = () => {
  const USER_DATA = JSON.parse(localStorage.getItem("userData"));
  const config = {
    headers: {
      Authorization: `Bearer ${USER_DATA?.token}`,
    },
  };

  const navigate = useNavigate();

  useEffect(() => {
    getuserDetails();
  }, []);

  const [transactionDetails, setTransactionDetails] = useState([]);
  const getuserDetails = () => {
    let req = {
      username: USER_DATA?.username,
    };
    // getUserDetailbyEmail
    httpClient
      .post("/getUserDetailbyEmail", req)
      .then((res) => {
        try {
          // console.log(res);
          if (res.data.statusCode == 200) {
            setTransactionDetails(res.data.result);
          } else {
            toast.error(res.data.message);
          }
        } catch (err) {
          toast.error("Something went wrong");
        }
      })
      .catch((res) => toast.error("Something went wrong"));
  };

  const goToCheckout = () => {
    navigate("/checkout", { state: { email: USER_DATA?.username } });
  };

 

  return (
    <div
      className="h-svh"
      style={{
        background: `url(${bgBlue})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <MainNavbar />
      <div className="flex items-center justify-center">
        <div className="w-full lg:max-w-[1024px] py-10 ">
          <h1 className="text-[#1D1D1F] m-0 text-4xl font-semibold">
            Welcome! {transactionDetails.length > 0 ? transactionDetails[0]?.firstname : USER_DATA?.username}
          </h1>
          <h1 className="text-[#1D1D1F] m-0 text-3xl mt-12 font-medium">
            Your Transactions
          </h1>

          <div className="mt-4 flex gap-6 flex-wrap justify-center ">
            {
                transactionDetails.length> 0 ? transactionDetails.map((trans) =>  {
                    return (
                      <Card className="w-[28rem] text-center">
                        <CardHeader >
                          <div className="flex justify-center w-full text-center">
                          <p className="text-2xl font-medium ">Plan : {trans?.planname}</p>
                          </div>
                        </CardHeader>
                        <CardBody className="text-center">
                          <p className="mb-2">Payment Type : <span className="font-medium">{trans?.paymenttype.toUpperCase()}</span></p>
                          <p className="mb-2">Payment Mode : <span className="font-medium">{trans?.mode.toUpperCase()}</span></p>

                           <p className="text-4xl fon-normal text-gray-600">
                            ${trans?.planvalue}
                          </p>
                        </CardBody>
                        <CardFooter   className=" flex justify-center">
                         {trans?.paymenttype == "partial" 
                         ? <Button onClick={goToCheckout} color="primary">
                            Got To Checkout
                          </Button> : 
                          <Button  color="success">
                          Payment Completed
                        </Button> 
                          }
                        </CardFooter>
                      </Card>
                    );
                  }) :
<Button onClick={goToCheckout} color="primary">
                            Got To Checkout
                          </Button>
                  
            }
            {}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
