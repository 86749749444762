import React, { useState } from "react";
import logo from "../assets/Logo.png";
import back from "../assets/images/back1.jpg";
import { Card } from "@nextui-org/react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

const SucessPage = () => {
  const [transId, setTransId] = useState(null);
  useEffect(() => {
    let transaction = JSON.parse(localStorage.getItem("transaction"));
    setTransId(transaction?.transactionid);
  }, []);
  const navigate = useNavigate()
  return (
    <div
      className={`flex items-center px-4 md:px-0 justify-center py-20 h-svh
       `}
      style={{ backgroundImage: `url(${back})`, backgroundSize: "cover" }}
    >
      <Card className="p-8 w-full md:w-[65vw] overflow-y-scroll h-[90vh] md:h-auto md:overflow-auto flex flex-col justify-center items-center text-center">
        <img src={logo} alt="Logo" className="w-80" />
        <h1 className="text-[2.8rem] font-semibold mt-8">
          Your Transaction has been Successful.
        </h1>
        <span className="text-3xl font-medium mt-4 text-[#757575]">
          Thank You!{" "}
        </span>
        <p onClick={() => navigate("/login")} className="text-lg text-blue-600 cursor-pointer">
          Click here to Login
        </p>
        {/* <span className="text-2xl font-medium mt-4 text-green-500">Your Transaction ID : {transId} </span> */}
      </Card>
    </div>
  );
};

export default SucessPage;
