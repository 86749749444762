import {
  Button,
  Card,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { httpClient } from "../axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import show from "../assets/icons/Show.svg";
import hide from "../assets/icons/Hide.svg";
import back from "../assets/images/back2.jpg";
import logo from "../assets/Logo.png";

const INTIALIZE = {
  username: "",
  password: "",
};
const validation = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required(),
});

const Login = () => {
  const [isVisible, setIsVisible] = React.useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const navigate = useNavigate();

  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const [passEmail, setPassEmail] = useState("");
  const [passMob, setPassMob] = useState("");
  const [nextBoll, setNextBool] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [userForgotData, setUserForgotData] = useState();

  const [isMobCorrect, setIsMobCorrect] = useState();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: INTIALIZE,
    validationSchema: validation,
    onSubmit: () => {
      saveUser();
    },
  });

  const saveUser = () => {
    const test = {
      username: values.username,
      password: values.password,
    };

    httpClient.post("/userLogin", test).then((res) => {
      try {
        // console.log(res);
        if (res.data.statusCode === "200") {
          localStorage.setItem("userData", JSON.stringify(res.data.result));
          navigate("/home");
        }
        toast.success(res.data.message);
      } catch (err) {
        toast.error("Something went wrong");
      }
    });
  };

  const close = () => {
    onClose();
    setIsInvalid(false);
    setNextBool(false);
    setPassEmail("");
  };

  const nextPage = () => {
    if (passEmail.length > 0) {
      const test = {
        username: passEmail,
        password: "",
      };

      httpClient.post("/getuserDetailbyEmail", test).then((res) => {
        try {
          console.log(res);
          if (res.data.statusCode === "200") {
            setUserForgotData(res.data.result);
            setNextBool(true);
            setIsInvalid(false);
          } else {
            toast.error(res.data.message);
          }
        } catch (err) {
          toast.error("Something went wrong");
        }
      });
    } else {
      setIsInvalid(true);
    }
  };

  const submitPass = () => {
    if (passMob === userForgotData?.mobileno) {
      setIsMobCorrect(true);
      const test = {
        username: passEmail,
        password: "",
      };

      httpClient.post("/sendPassword", test).then((res) => {
        try {
          if (res.data.statusCode === "200") {
            close();
          }
          toast.success(res.data.message);
        } catch (err) {
          toast.error("Something went wrong");
        }
      });
    } else {
      setIsMobCorrect(false);
    }
  };

  const onChangePass = (e) => {
    setIsInvalid(false);
    setPassEmail(e.target.value);
  };
  const onChangeMobile = (e) => {
    setPassMob(e.target.value);
  };

  return (
    <div
      className="flex flex-col items-center justify-center gap-4 h-dvh"
      style={{ backgroundImage: `url(${back})`, backgroundSize: "cover" }}
    >
      <h1 className="font-bold text-4xl mb-8">IACF Membership Application</h1>
      <img src={logo} alt="Logo" className="w-3/12 mb-2" />
      {/* <Link href="/register"> </Link> */}
      <div className="w-[35rem]">
        <Button onClick={() => navigate("/register")} color="primary" size="md" className="" variant="shadow" radius="full">
          Sign Up ? Click here to create a account{" "}
        </Button>
      </div>

      <Card className="p-8 w-[35rem]">
        <h1 className="font-medium text-gray-700 text-2xl mb-4">
          {" "}
          Already Member? Signin
        </h1>
        <form
          id="reg"
          className="flex flex-col gap-6"
          noValidate
          onSubmit={handleSubmit}
        >
          <div className="flex gap-4 items-start flex-col">
            <Input
              isRequired
              label="Email"
              labelPlacement="outside"
              value={values.username}
              name="username"
              isInvalid={Boolean(touched.username && errors.username)}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={
                Boolean(touched.username && errors.username) &&
                "Please enter a valid username"
              }
            />
            <Input
              isRequired
              label="Password"
              labelPlacement="outside"
              type={isVisible ? "text" : "password"}
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={toggleVisibility}
                >
                  {isVisible ? (
                    <Image alt="Hide" src={hide} />
                  ) : (
                    <Image alt="Show" src={show} />
                  )}
                </button>
              }
              value={values.password}
              name="password"
              isInvalid={Boolean(touched.password && errors.password)}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={
                Boolean(touched.password && errors.password) &&
                "Please enter a valid password"
              }
            />
          </div>
          <div className="mt-6 flex flex-col">
            <Button
              className="flex-1 py-2 text-sm"
              type="submit"
              color="primary"
            >
              Signin
            </Button>
            <div className="flex  justify-between items-center mt-2">
              {" "}
              <Link className="cursor-pointer" onPress={onOpen}>
                Forgot Password
              </Link>
              <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                  {(onClose) => (
                    <>
                      <ModalHeader className="flex flex-col gap-1">
                        Forgot Password
                      </ModalHeader>
                      <ModalBody>
                        <Input
                          type="email"
                          isInvalid={isInvalid}
                          onChange={(e) => onChangePass(e)}
                          placeholder="Enter a Email"
                        />
                        {nextBoll && (
                          <Input
                            type="text"
                            onChange={(e) => onChangeMobile(e)}
                            placeholder="Enter a Mobile Number"
                            isInvalid={isMobCorrect === false}
                            errorMessage={
                              isMobCorrect === false &&
                              "Mobile Number Dosen't Match"
                            }
                          />
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <Button color="danger" variant="light" onPress={close}>
                          Close
                        </Button>
                        {nextBoll ? (
                          <Button color="primary" onPress={submitPass}>
                            Submit
                          </Button>
                        ) : (
                          <Button color="primary" onPress={nextPage}>
                            Next
                          </Button>
                        )}
                      </ModalFooter>
                    </>
                  )}
                </ModalContent>
              </Modal>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default Login;
